import Popup from '@/lib/Popup';
import EditPriceModal from '@/components/modals/EditPriceModal';

export const openEditPriceModal = price => {
	const popup = new Popup({
		component: EditPriceModal,
		props: { price },
		params: {
			transition: 'scale',
			name: 'edit-price-modal',
			width: '100%',
			height: '100%',
			classes: 'modal-popup__overlay'
		}
	});
	
	popup.show();
};

export default { openEditPriceModal };
