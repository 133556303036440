<template>
    <div class="modal-popup" @click="resetAlert" @keyup.13="onAddClick">
        <div class="mb-3 modal-popup__title">
            Добавление цены

            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#2D4C70"
                 class="bi bi-x-lg modal-popup__close"
                 viewBox="0 0 16 16"
                 @click="close"
            >
                <path
                    d="M1.293 1.293a1 1 0 0 1 1.414 0L8 6.586l5.293-5.293a1 1 0 1 1 1.414 1.414L9.414 8l5.293 5.293a1 1 0 0 1-1.414 1.414L8 9.414l-5.293 5.293a1 1 0 0 1-1.414-1.414L6.586 8 1.293 2.707a1 1 0 0 1 0-1.414z"/>
            </svg>
        </div>
        <label class="modal-popup__label">Описание</label>
        <textarea class="form-control" placeholder="Введите описание" v-model="description" ref="input"/>
    
        <div class="currency-picker">
            <label class="modal-popup__label">Валюта</label>
            <b-dropdown :text="selectedCurrencyName">
                <b-dropdown-item
                    v-for="option of options" :key="option.value" @click="selectCurrency"
                    :data-value="option.value"
                >
                    {{ option.name }}
                </b-dropdown-item>
            </b-dropdown>
        </div>
        
        <label class="modal-popup__label">Цена</label>
        <input type="number" v-model.number="currencyValue" class="form-control mt-2"
               min=0 oninput="validity.valid||(value=0)"
        />
        <ui-button class="mt-3 mb-2" @clicked="onAddClick">Добавить</ui-button>
        <div v-if="alert.length" class="alert alert-danger" role="alert">{{ alert }}</div>
    </div>
</template>

<script>
import { getCurrencyName } from '@/utils';
import {mapActions, mapGetters} from 'vuex';
import bus from '@/plugins/bus';

export default {
    data: () => ({
        description: '',
        alert: '',
        canReset: false,
        selectedCurrencyId: 0,
        currencyValue: 0
    }),
    components: {
        UiButton: () => import('@/components/ui/UiButton')
    },
    computed: {
        ...mapGetters('firebase', ['getNewPriceID']),
        selectedCurrencyName() {
            return getCurrencyName(this.selectedCurrencyId);
        },
        options() {
            return this.$store.getters['firebase/currenciesOptions'];
        },
    },
    async created() {
        await this.getConfig();
        this.fillSelects();
    },
    mounted() {
        this.$refs.input.focus();
    },
    methods: {
        ...mapActions('firebase', ['getConfig', 'savePrice']),
        fillSelects() {
            this.selectedCurrencyId = this.options[0].value;
        },
        resetAlert() {
            if (this.canReset) {
                this.alert = '';
                this.canReset = false;
            }
        },
        setAlert(message) {
            this.alert = message;
            setTimeout(() => this.canReset = true, 250);
        },
        async onAddClick() {
            const response = await this.savePrice({
                id: this.getNewPriceID,
                description: this.description,
                money: {
                    id: this.selectedCurrencyId,
                    value: this.currencyValue
                }
            });

            if (!response.error) {
                bus.$emit('show-notification-message', 'Добавлено');
                this.close();
            } else
                alert(`Ошибка: ${response.error.toString()}`);
        },
        close() {
            this.$modal.hide('add-price-modal');
        },
        selectCurrency(event) {
            this.selectedCurrencyId = +event.target.dataset.value;
        }
    }
};
</script>

<style lang="scss" scoped>
.currency-picker {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
</style>
