import Popup from '@/lib/Popup';
import AddPriceModal from '@/components/modals/AddPriceModal';

export const openAddPriceModal = () => {
	const popup = new Popup({
		component: AddPriceModal,
		props: {},
		params: {
			transition: 'scale',
			name: 'add-price-modal',
			width: '100%',
			height: '100%',
			classes: 'modal-popup__overlay'
		}
	});
	
	popup.show();
};

export default { openAddPriceModal };
