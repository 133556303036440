<template>
    <div class="modal-popup" @click="resetAlert" @keyup.13="onSaveClick">
        <div class="mb-3 modal-popup__title">
            Редактирование цены
            
            <svg
                xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#2D4C70"
                class="bi bi-x-lg modal-popup__close"
                viewBox="0 0 16 16"
                @click="close"
            >
                <path
                    d="M1.293 1.293a1 1 0 0 1 1.414 0L8 6.586l5.293-5.293a1 1 0 1 1 1.414 1.414L9.414 8l5.293 5.293a1 1 0 0 1-1.414 1.414L8 9.414l-5.293 5.293a1 1 0 0 1-1.414-1.414L6.586 8 1.293 2.707a1 1 0 0 1 0-1.414z"
                />
            </svg>
        </div>
        <label class="modal-popup__label">Описание</label>
        <textarea class="form-control" placeholder="Введите описание" v-model="description" />
        
        <div class="currency-picker">
            <label class="modal-popup__label">Валюта</label>
            <b-dropdown :text="selectedCurrencyName">
                <b-dropdown-item
                    v-for="option of options" :key="option.value" @click="selectCurrency"
                    :data-value="option.value"
                >
                    {{ option.name }}
                </b-dropdown-item>
            </b-dropdown>
        </div>
        
        <label class="modal-popup__label">Цена</label>
        <input
            type="number" v-model.number="currencyValue" class="form-control mt-2"
            min="0" oninput="validity.valid||(value=0)"
        />
        <ui-button class="mt-3 mb-2" @clicked="onSaveClick">Сохранить</ui-button>
        <div v-if="alert.length" class="alert alert-danger" role="alert">{{ alert }}</div>
    </div>
</template>

<script>
import { mapActions } from 'vuex';
import bus from '@/plugins/bus';
import { getCurrencyName } from '@/utils';

export default {
    props: {
        price: Object
    },
    data: () => ({
        description: 'Ожидание',
        selectedCurrencyId: 0,
        currencyValue: 0,
        alert: '',
        canReset: false,
    }),
    computed: {
        selectedCurrencyName() {
            return getCurrencyName(this.selectedCurrencyId);
        },
        options() {
            return this.$store.getters['firebase/currenciesOptions'];
        },
    },
    async created() {
        await this.getConfig();
        this.fillFields();
    },
    components: {
        UiButton: () => import('@/components/ui/UiButton')
    },
    methods: {
        ...mapActions('firebase', ['getConfig', 'savePrice']),
        fillFields() {
            this.description = this.price.description;
            this.selectedCurrencyId = this.price.money.id;
            this.currencyValue = this.price.money.value;
        },
        async onSaveClick() {
            const response = await this.savePrice({
                id: this.price.id,
                description: this.description,
                money: {
                    id: this.selectedCurrencyId,
                    value: this.currencyValue
                }
            });
            
            if (!response.error) {
                bus.$emit('show-notification-message', 'Обновлено');
                this.close();
            } else {
                bus.$emit('show-notification-message', `Ошибка: ${response.error.toString()}`);
            }
        },
        resetAlert() {
            if (this.canReset) {
                this.alert = '';
                this.canReset = false;
            }
        },
        setAlert(message) {
            this.alert = message;
            setTimeout(() => this.canReset = true, 250);
        },
        close() {
            this.$modal.hide('edit-price-modal');
        },
        selectCurrency(event) {
            this.selectedCurrencyId = +event.target.dataset.value;
        }
    }
};
</script>

<style lang="scss" scoped>
.currency-picker {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
</style>
